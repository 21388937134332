<template>
  <div class="user-list">
    <div class="app-list">
    <div class="app-header">
      <div class="el-row">
        <div class="title-bar el-col el-col-24">
          <span class="title">合同模板</span>
        </div>
      </div>
    </div>
    <div>
      <el-button class="onExport" type="primary" @click="AddContrant()">添加合同模板</el-button>
    </div>
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        // title: "合同模板",
        columns: [
          {
            label: "模板编号",
            key: "modelNo",
            on: row => {
              _this.$router.push(
                "/main/ContractTemplate/adddetail/show/" + row.id
              );
            }
          },
          {
            label: "合同模板名称",
            key: "modelName"
          },
          {
            label: "创建人",
            key: "creater"
          },
          {
            label: "创建时间",
            key: "createDate"
          },
          {
            label: "修改时间",
            key: "updateDate"
          },
          {
            label: "状态",
            key: "state"
          },
          {
            label: "操作",
            key: "action",
            type: "action",
            opt: {
              list: [
                {
                  label: "编辑",
                  on(row) {
                    _this.$router.push(
                      "/main/ContractTemplate/adddetail/edit/" + row.id
                    );
                  }
                },
                {
                  label: "作废",
                  on(row) {
                    _this.dialogVisible = true;
                    const h = _this.$createElement;
                    _this
                      .$msgbox({
                        title: "确定提示",
                        message: h("p", null, [
                          h(
                            "span",
                            {
                              style:
                                "color: #333;font-size: 16px;margin-left:98px"
                            },
                            "确认要作废此合同模版吗？"
                          )
                        ]),
                        showCancelButton: true,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消"
                      })
                      .then(action => {
                        var dto = {
                          id: row.id,
                          state: 2
                        };
                        _this
                          .post(
                            "/tenant-service/leaseContractModel/modifyState",
                            dto
                          )
                          .then(function() {
                            _this.$message({
                              showClose: true,
                              message: "设为作废合同成功！",
                              type: "success"
                            });
                          });
                        _this.$refs.myList.get();
                      });
                  }
                },
                {
                  label: "设为默认",
                  on(row) {
                    _this.dialogVisible = true;
                    const h = _this.$createElement;
                    _this
                      .$msgbox({
                        title: "确定提示",
                        message: h("p", null, [
                          h(
                            "span",
                            {
                              style:
                                "color: #333;font-size: 16px;margin-left:98px"
                            },
                            "仅可一份合同可设为默认使用，确认"
                          ),
                          h(
                            "p",
                            {
                              style: "font-size:14px;margin-left:98px"
                            },
                            "将此合同设为默认？"
                          )
                        ]),
                        showCancelButton: true,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消"
                      })
                      .then(action => {
                        var dto = {
                          id: row.id,
                          state: 3
                        };
                        _this
                          .post(
                            "/tenant-service/leaseContractModel/modifyState",
                            dto
                          )
                          .then(function() {
                            _this.$message({
                              showClose: true,
                              message: "设为默认合同成功！",
                              type: "success"
                            });
                          });
                        _this.$refs.myList.get();
                      });
                  }
                }
                // {
                //   label: "下载",
                //   on(row) {}
                // }
              ]
            }
          }
        ]
      }
    };
  },
  created() {
    console.log("user-list created!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNumber: opt.skip,
        pageSize: opt.limit
      };
      this.post("/tenant-service/leaseContractModel/selectPageData", dto, {
        isUseResponse: true
      }).then(res => {
        if (res.data.code == 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.createDate =
              item.createDate == null
                ? ""
                : moment(item.createDate).format("YYYY-MM-DD HH:mm:ss");
            item.updateDate =
              item.updateDate == null
                ? ""
                : moment(item.updateDate).format("YYYY-MM-DD HH:mm:ss");
            if (item.state == "3") {
              item.action = [true, false, false];
            } else if (item.state == "2"){
              item.action =[false, false, false];
            }
            else if (item.state == "1"){
              item.action =[true, true, true];
            }
            item.state = ["", "未使用", "已作废", "默认使用"][item.state];
          }
        }
        opt.cb(res.data);
      });
    },
    AddContrant() {
      this.$router.push("/main/ContractTemplate/detail/add/0");
    }
  }
};
</script>
<style lang="scss">
.app-header {
  .el-row {
    // margin-bottom: 10px;
    line-height: 30px;
  }
  .title-bar {
    height: 40px;
    line-height: 40px;
    .title {
      font-size: 18px;
      color: #303133;
    }
  }
}
</style>
<style lang="scss" scoped>
.user-list{
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 60px);
}
.app-list{
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-list" }, [
    _c(
      "div",
      { staticClass: "app-list" },
      [
        _vm._m(0),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "onExport",
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    _vm.AddContrant()
                  }
                }
              },
              [_vm._v("添加合同模板")]
            )
          ],
          1
        ),
        _c("app-list", {
          ref: "myList",
          attrs: { opt: _vm.opt },
          on: { get: _vm.onGet }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-header" }, [
      _c("div", { staticClass: "el-row" }, [
        _c("div", { staticClass: "title-bar el-col el-col-24" }, [
          _c("span", { staticClass: "title" }, [_vm._v("合同模板")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }